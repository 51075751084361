import { PRICING, UNIT } from 'types'

export const timestampToDatetimeInputString = (timestamp: number) => {
  const date = new Date(timestamp + _getTimeZoneOffsetInMs())
  // slice(0, 19) includes seconds
  return date.toISOString().slice(0, 19)
}

const _getTimeZoneOffsetInMs = () => {
  return new Date().getTimezoneOffset() * -60 * 1000
}

export const setDefaultDueDate = () => {
  const date = new Date()
  const due = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0
  )
  due.setDate(due.getDate() + 2)
  return timestampToDatetimeInputString(due.getTime())
}

const SPACE = 2
const CONV_MM = 25.4
const CONV_CM = 10
const WIDTH = 210
const HEIGHT = 297
const A4Area = WIDTH * HEIGHT
const area125 = 1772.4099999999996

export const compute = (
  width: number,
  height: number,
  unit: UNIT,
  pcs: number = 0
) => {
  let widthMM: number = 0
  let heightMM: number = 0
  if (unit === UNIT.CM) {
    widthMM = width * CONV_CM + SPACE
    heightMM = height * CONV_CM + SPACE
  } else if (unit === UNIT.MM) {
    widthMM = width + SPACE
    heightMM = height + SPACE
  } else {
    widthMM = width * CONV_MM + SPACE
    heightMM = height * CONV_MM + SPACE
  }

  const areaPlusSpacing = widthMM * heightMM
  const pcsPerA4 = Math.floor(A4Area / areaPlusSpacing) || 0

  let low = 0
  let med = 0
  let high = 0
  let ultra = 0
  let price = 0

  if (area125 > areaPlusSpacing) {
    const cut = pcsPerA4 * 0.1
    low = Number(parseFloat(`${(44 + cut) / pcsPerA4}`).toFixed(2))
    med = Number(parseFloat(`${(44 + cut) / pcsPerA4}`).toFixed(2))
    high = Number(parseFloat(`${(37 + cut) / pcsPerA4}`).toFixed(2))
    ultra = Number(parseFloat(`${(31 + cut) / pcsPerA4}`).toFixed(2))
  } else {
    const cut = pcsPerA4 * 0.101
    low = Number(parseFloat(`${(44 + cut) / pcsPerA4}`).toFixed(2))
    med = Number(parseFloat(`${(34 + cut) / pcsPerA4}`).toFixed(2))
    high = Number(parseFloat(`${(27 + cut) / pcsPerA4}`).toFixed(2))
    ultra = Number(parseFloat(`${(23 + cut) / pcsPerA4}`).toFixed(2))
  }

  if (pcs < PRICING.MED) {
    price = Number(parseFloat(`${pcs * low}`).toFixed(2))
  } else if (pcs >= PRICING.MED && pcs < PRICING.HIGH) {
    price = Number(parseFloat(`${pcs * med}`).toFixed(2))
  } else if (pcs >= PRICING.HIGH && pcs < PRICING.ULTRA) {
    price = Number(parseFloat(`${pcs * high}`).toFixed(2))
  } else {
    price = Number(parseFloat(`${pcs * ultra}`).toFixed(2))
  }

  return {
    low,
    med,
    high,
    ultra,
    pcsPerA4,
    price,
    a4pages: Number(parseFloat(`${pcs / pcsPerA4}`).toFixed(2)),
  }
}

export const getPricing = (pcs: number) => {}

export const getTimeFirebaseDate = (orderDate: any) => {
  let orderDateTime = new Date(orderDate.seconds * 1000)
  return orderDateTime
}
