export interface IOrder {
  brand: string
  clientName: string
  comment?: string
  contact?: string
  dueDate: any
  id: string
  layoutLink: string
  orderDate: any
  perSheet: number
  poc: string
  price: number
  printTime: number
  quantity: number
  status: string
  totalA4?: number
  type: string
  size?: string
  rush: any,
  width: number;
  height: number;
  unit: string;
}

export enum UNIT {
  CM = 'cm',
  MM = 'mm',
  IN = 'in',
}

export enum PRICING {
  LOW = 399,
  MED = 400,
  HIGH = 1000,
  ULTRA = 2000,
}

export enum EXPENSES {
  Consumables = 'Consumables',
  Logistics = 'Logistics',
  Marketing = 'Marketing',
  ShippingFee = 'ShippingFee',
  ElectricBill = 'ElectricBill',
  MealAllowance = 'MealAllowance',
  LoadAllowance = 'LoadAllowance',
}
