import React, { useEffect, useRef, useState } from 'react'
import {
  Flex,
  Box,
  Input,
  Text,
  Heading,
  Select,
  Button,
  Grid,
  Link,
} from 'theme-ui'
import { PRICING } from 'types'
import { compute } from 'helpers'
import Stores from 'components/stores'

interface IPricingDetails {
  perPiece: number
  pricingQty?: number
}
interface IPricing {
  low: IPricingDetails
  med: IPricingDetails
  high: IPricingDetails
  ultra: IPricingDetails
}
interface ICompute {
  height: number
  width: number
  pcs: number
}

const PRICING_A4 = {
  LOW: 44,
  MED: 34,
  HIGH: 27,
  ULTRA: 23,
}

export default function Compute() {
  const [pricing, setPricing] = useState<IPricing>({
    low: { perPiece: 0, pricingQty: PRICING.LOW },
    med: { perPiece: 0, pricingQty: PRICING.MED },
    high: { perPiece: 0, pricingQty: PRICING.HIGH },
    ultra: { perPiece: 0, pricingQty: PRICING.ULTRA },
  })
  const [computeValues, setComputeValues] = useState<ICompute>({
    height: 1.5,
    width: 1.5,
    pcs: 1,
  })
  const [pages, setPages] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [selectedPrice, setSelectedPrice] = useState<number>(0)
  const [selectedUnit, setSelectedUnit] = useState<number>(0)
  const [pcsPerA4, setPcsPerA4] = useState<number>(0)
  const docHeight = useRef<HTMLInputElement | any>(null)
  const docWidth = useRef<HTMLInputElement | any>(null)
  const docUnit = useRef<HTMLSelectElement | any>(null)
  const docPcs = useRef<HTMLInputElement | any>(null)

  const onCompute = () => {
    if (docUnit) {
      setSelectedUnit(docUnit.current.value)
    }
    if (docHeight && docWidth && docPcs) {
      const h = Number(docHeight.current.value)
      const w = Number(docWidth.current.value)
      const pcs = Number(docPcs.current.value)
      const computations = compute(w, h, docUnit.current.value)
      setPcsPerA4(computations.pcsPerA4)

      setPages(Number(parseFloat(`${pcs / computations.pcsPerA4}`).toFixed(2)))

      if (pcs < PRICING.MED) {
        setSelectedPrice(PRICING.LOW)
        setTotal(Number(parseFloat(`${pcs * computations.low}`).toFixed(2)))
      } else if (pcs >= PRICING.MED && pcs < PRICING.HIGH) {
        setSelectedPrice(PRICING.MED)
        setTotal(Number(parseFloat(`${pcs * computations.med}`).toFixed(2)))
      } else if (pcs >= PRICING.HIGH && pcs < PRICING.ULTRA) {
        setSelectedPrice(PRICING.HIGH)
        setTotal(Number(parseFloat(`${pcs * computations.high}`).toFixed(2)))
      } else {
        setSelectedPrice(PRICING.ULTRA)
        setTotal(Number(parseFloat(`${pcs * computations.ultra}`).toFixed(2)))
      }

      setComputeValues({
        height: h,
        width: w,
        pcs,
      })

      const newPricing = {
        low: { perPiece: computations.low },
        med: { perPiece: computations.med },
        high: { perPiece: computations.high },
        ultra: { perPiece: computations.ultra },
      }
      setPricing({
        low: { ...pricing.low, ...newPricing.low },
        med: { ...pricing.med, ...newPricing.med },
        high: { ...pricing.high, ...newPricing.high },
        ultra: { ...pricing.ultra, ...newPricing.ultra },
      })
    }
  }

  const onChangeDropdown = () => {
    onCompute()
  }

  useEffect(() => {
    onCompute()
  }, [])

  return (
    <Box
      sx={{
        position: 'relative',
        textShadow: '2px 2px 0 rgba(0,0,0,0.3)',
      }}
    >
      <Heading
        mb={2}
        sx={{
          fontSize: ['25px', '31px', '41px'],
          textShadow: '4px 4px 0 rgba(0,0,0,0.3)',
          paddingRight: ['100px', '0', '0'],
        }}
      >
        GET YOUR STICKER ESTIMATE NOW!
      </Heading>
      <Flex
        sx={{
          flexDirection: 'column',
        }}
      >
        <Box sx={{ flexBasis: '30%' }}>
          <Flex
            sx={{
              alignItems: ['', 'flex-end', 'flex-end'],
              flexDirection: ['column', 'row', 'row'],
              justifyContent: ['space-between'],
              div: {
                width: ['100%', '33%', '33%'],
              },
            }}
          >
            <Box mt={2} sx={{ h4: { fontWeight: '600' } }}>
              <Heading as="h4" mb={1}>
                Width
              </Heading>
              <Input
                ref={docWidth}
                defaultValue="2"
                placeholder="Width"
                type="number"
                onKeyUp={onCompute}
              />
            </Box>
            <Box mt={2} sx={{ h4: { fontWeight: '600' } }}>
              <Heading as="h4" mb={1}>
                Height
              </Heading>
              <Input
                ref={docHeight}
                defaultValue="2"
                placeholder="Height"
                type="number"
                onKeyUp={onCompute}
              />
            </Box>
            <Box mt={2} sx={{ h4: { fontWeight: '600' } }}>
              <Heading as="h4" mb={1}>
                Pcs
              </Heading>
              <Input
                ref={docPcs}
                defaultValue="1"
                placeholder="Pcs"
                type="number"
                onKeyUp={onCompute}
              />
            </Box>
          </Flex>
          <Select
            my={2}
            p={1}
            defaultValue="inches"
            sx={{
              variant: 'styles.select.xs',
              width: '100px',
              option: {
                color: '#231F1F',
              },
            }}
            ref={docUnit}
            onChange={onChangeDropdown}
          >
            <option>in</option>
            <option>cm</option>
            <option>mm</option>
          </Select>
        </Box>
        <Box
          sx={{
            flexBasis: '70%',
            padding: '0',
            width: '100%',
            borderRadius: '25px',
          }}
        >
          <Flex
            sx={{
              flexDirection: ['row', 'column', 'column'],
              textAlign: ['left', 'center', 'center'],
              div: {
                div: {
                  padding: ['0 10px', '0', '0 2px'],
                },
              },
            }}
          >
            <Grid
              gap={0}
              columns={[1, 4, 4]}
              sx={{
                div: {
                  fontSize: ['28px', '22px', '22px'],
                  fontWeight: 'bold',
                  borderRadius: ['4px 0 0 4px', '4px 4px 0 0', '4px 4px 0 0'],
                },
              }}
            >
              {Object.entries(pricing).map(([, pricing]) => {
                return (
                  <Text
                    bg={selectedPrice === pricing.pricingQty && 'secondary'}
                    color="text"
                    p={1}
                  >
                    {pricing.pricingQty} pcs
                  </Text>
                )
              })}
            </Grid>
            <Grid
              gap={0}
              columns={[1, 4, 4]}
              sx={{
                div: {
                  fontSize: ['28px', '22px', '22px'],
                  fontWeight: '700',
                  borderRadius: ['0 4px 4px 0', '0 0 4px 4px', '0 0 4px 4px'],
                },
              }}
            >
              {Object.entries(pricing).map(([key, pricing], i) => {
                return (
                  <Text
                    bg={selectedPrice === pricing.pricingQty && 'secondary'}
                    p={1}
                    color="muted"
                    key={i}
                  >
                    P {pricing.perPiece}
                  </Text>
                )
              })}
            </Grid>
          </Flex>
          <Box sx={{ fontSize: '12px' }}>** bulk prices are based per design.</Box>
          <Grid
            gap={0}
            columns={1}
            sx={{
              div: { fontSize: ['23px', '23px', '23px'], fontWeight: '500' },
              maxWidth: '500px',
            }}
          >
            <Text p={1} color="muted">
              {`${computeValues.pcs}${
                Number(computeValues.pcs) > 1 ? 'pcs' : 'pc'
              } | ${computeValues.height} x ${
                computeValues.width
              } ${selectedUnit} | ${total} pesos`}
            </Text>
            {Object.entries(pricing).map(([, pricing]) => {
              if (selectedPrice === pricing.pricingQty) {
                return (
                  <>
                    <Text p={1}>{`${pricing.perPiece} pesos each`}</Text>
                    <Text
                      p={1}
                      sx={{ fontSize: '18px !important' }}
                    >{`${pcsPerA4}pcs /page`}</Text>
                    <Text
                      p={1}
                      sx={{ fontSize: '18px !important' }}
                    >{`${pages} A4 pages`}</Text>
                  </>
                )
              }
            })}
            
            <Stores />
          </Grid>
        </Box>
      </Flex>
    </Box>
  )
}
