import React, { useEffect } from 'react'
import { Flex, jsx, Box, Link, Button } from 'theme-ui'
import Compute from 'components/compute'
import Layout from 'components/layout'
import SEO from 'components/seo'
import Messenger from 'assets/messenger.svg'
import Shopee from 'assets/shopee.svg'

export default function Stores() {
  return (
    <>
      <Box
        sx={{
          img: { height: '30px', width: '30px' },
          svg: {
            height: '30px',
            width: '30px',
          },
          button: {
            transition: 'all .3s ease',
            '&:hover': {
              opacity: 0.5,
            },
          },
        }}
      >
        <Button p={0} sx={{ variant: 'fontSizes.0' }} bg="muted" mr={2} mt={2}>
          <Link
            px={3}
            py={2}
            variant="links.button"
            color="background"
            href="https://bit.ly/printadomessenger"
            target="_blank"
            title="Printado Printing Services Messenger"
          >
            <Flex sx={{ alignItems: 'center' }}>
              <Messenger sx={{ width: '20px' }} />
            </Flex>
          </Link>
        </Button>
        <Button p={0} sx={{ variant: 'fontSizes.0' }} bg="muted" mt={2}>
          <Link
            px={3}
            py={2}
            variant="links.button"
            color="background"
            href="http://bit.ly/printadoshopee"
            target="_blank"
            title="Printado Printing Services Shopee"
          >
            <Flex sx={{ alignItems: 'center' }}>
              <Shopee />
            </Flex>
          </Link>
        </Button>
      </Box>
    </>
  )
}
